@use '../../mixins';
@use '../../constants';

/* SELECT MENU */

.form-select-menu {
  position: relative;
}

.form-select-button {
  box-sizing: border-box;
  @include mixins.typescale-small;
  line-height: 32px;
  font-weight: 400;
  height: 32px;
  outline: none;
  padding: 0 16px;
  text-align: left;
  width: 100%;
  cursor: pointer;

  strong {
    font-weight: 600;
    margin-right: 8px;
    text-transform: uppercase;
  }
}

.form-select-dropdown {
  border-radius: 4px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: constants.$layer-2;

  li {
    cursor: pointer;
    @include mixins.typescale-default;
    line-height: 32px;
    margin: 0;
    padding: 0 16px 0 40px;
    position: relative;
    transition: all .2s;

    .symbol {
      left: 16px;
      position: absolute;
      top: 8px;
      z-index: constants.$layer-5;
    }
  }
}
