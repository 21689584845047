@use '../../constants';
@use '../../mixins';

code-example,
code-tabs {
  clear: both;
  display: block;
}

code-example {
  &:not(.no-box) {
    border-radius: 5px;
    margin: 16px auto;
  }

  &.no-box {
    pre {
      margin: 0;
    }
    code {
      background-color: transparent;
    }
  }

  code {
    overflow: auto;
  }
}

code-example,
code-tabs {
  .mat-card {
    padding: 0;
    border-radius: 5px;
  }
  code {
    overflow: auto;
  }
}

// TERMINAL / SHELL TEXT STYLES
code-example header {
  border-radius: 5px 5px 0 0;
  @include mixins.typescale-default;
  padding: 8px 16px;
}

code-tabs div .mat-tab-body-content {
  height: auto;
}

code-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body {
  overflow-y: hidden;
}

code-tabs mat-tab-body-content .fadeIn {
  animation: opacity 2s ease-in;
}

aio-code pre {
  display: flex;
  min-height: 32px;
  margin: 16px 24px;
  white-space: pre-wrap;
  align-items: center;

  code span {
    line-height: 24px;
  }
}

.copy-button {
  position: absolute;
  top: -7px;
  right: -19px;
  padding: 0;

  background-color: transparent;
  border: none;
  cursor: pointer;
}

.code-tab-group .mat-tab-label {
  white-space: nowrap;
}

.code-tab-group .mat-tab-body-content {
  height: auto;
  transform: none;
}

[role='tabpanel'] {
  transition: none;
}

.sidenav-content code a {
  color: inherit;
  font-size: inherit;
}

/* PRETTY PRINTING STYLES for prettify.js. */

.prettyprint {
  position: relative;
}

/* Specify class=linenums on a pre to get line numbering */
ol.linenums {
  margin: 0;
  font-family: constants.$main-font;
  li {
    margin: 0;
    font-family: constants.$code-font;
    font-size: 90%;
    line-height: 24px;
  }
}
