@use '../../mixins';

.card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 24px 0;
}

.docs-card {
  @include mixins.card(194px, 30%);
  max-width: 340px;
  min-width: 262px;
  margin: 24px 8px;
  padding-bottom: 48px;
  position: relative;

  &:hover {
    text-decoration: none;

    p {
      padding: 0 16px;
    }

    .card-footer {
      line-height: 32px;
      padding: 8px 16px;
    }
  }


  section {
    @include mixins.typescale-large;
    margin: 0;
    padding: 32px 0 24px;
    text-transform: none;
    text-align: center;
  }

  p {
    @include mixins.typescale-small;
    padding: 0 16px;
    margin: 0;
    text-align: center;
  }

  .card-footer {
    bottom: 0;
    box-sizing: border-box;
    line-height: 48px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: right;
    a {
      @include mixins.typescale-small;
    }
  }
  .card-footer.center {
    text-align: center;
  }
}

.card-section {
  @include mixins.card(auto, 90%);
  padding: 16px 32px;
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  h1, h2, h3, h4, h5, h6 {
    margin: 8px 0;
  }

  a, .button, button {
    text-align: center;
  }

}
