/* ==============================
   BASE STYLES
   ============================== */
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
body {
  font-size: 16px;
  line-height: 24px;
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  display: inline-block;
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  margin: 8px 0px;
}
@media screen and (max-width: 600px) {
  h1 {
    margin-top: 0;
  }
}

h2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  margin: 32px 0px 24px;
  clear: both;
}

h3 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin: 24px 0px 12px;
  clear: both;
}

h4 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 8px 0px;
  clear: both;
}

h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 8px 0px;
  clear: both;
}

h6 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 8px 0px;
  clear: both;
}

h2 a, h3 a, h4 a, h5 a, h6 a {
  font-size: inherit;
}
@media screen and (max-width: 600px) {
  h2, h3, h4, h5, h6 {
    margin: 8px 0;
  }
}

.mat-tab-body-wrapper h2 {
  margin-top: 0;
}

p, ol, ul, ol, li, input, a {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  font-weight: 400;
}
p > em, ol > em, ul > em, ol > em, li > em, input > em, a > em {
  letter-spacing: 0.3px;
}

ol li, ol p {
  margin: 4px 0;
}

li p {
  margin: 0;
}

a {
  text-decoration: none;
}

.app-toolbar a {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-transform: uppercase;
  padding: 21px 0;
}

aio-shell.page-home .app-toolbar a {
  color: #412846;
}

strong {
  font-weight: 600;
}

table {
  border-collapse: collapse;
  border-radius: 2px;
  border-spacing: 0;
  margin: 12px 0 32px;
}

table tbody th {
  max-width: 100px;
  padding: 13px 32px;
  text-align: left;
}

td {
  font-weight: 400;
  padding: 8px 30px;
  letter-spacing: 0.3px;
}
td p {
  margin: 0;
}

th {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 13px 32px;
  text-align: left;
}

p > code, li > code, td > code, th > code {
  font-family: "Droid Sans Mono", monospace;
  font-size: 85%;
  letter-spacing: 0;
  line-height: 1;
  padding: 2px 0;
  background-color: #F1F1F1;
  border-radius: 4px;
}

code {
  font-family: "Droid Sans Mono", monospace;
  font-size: 90%;
}

/* ==============================
   LAYOUT STYLES
   ============================== */
.api-body {
  max-width: 1200px;
}
.api-body table {
  margin: 12px 0 24px;
}
.api-body table th {
  text-transform: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
.api-body table tr {
  border-bottom: 1px solid #DBDBDB;
}
.api-body table td {
  vertical-align: middle;
}
.api-body table hr {
  margin: 16px 0;
}
.api-body table tr:last-child {
  border-bottom: none;
}
.api-body table.item-table td {
  padding: 32px;
}
.api-body table.list-table td {
  padding: 16px 24px;
}
.api-body table .short-description {
  margin-left: 0;
}

aio-shell.page-docs .sidenav-content {
  margin: auto;
}

.sidenav-content {
  min-height: 100vh;
  padding: 80px 3rem 1rem;
}

@media (max-width: 600px) {
  aio-menu {
    display: none;
  }
  .sidenav-content {
    min-height: 450px;
    padding: 80px 1rem 1rem;
  }
}
.sidenav-container {
  width: 100%;
  height: 100vh;
}

.sidenav-content button {
  min-width: 24px;
}

#guide-change-log h2::before {
  content: "";
  display: block;
  height: 1px;
  margin: 24px 0px;
  background: #DBDBDB;
}

.no-animations aio-doc-viewer > * {
  transition: none !important;
}

.video-container {
  width: 100%;
  max-width: 750px;
  margin: auto;
}

.video-responsive-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-responsive-wrapper > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

footer {
  position: relative;
  line-height: 24px;
  flex: 1;
  padding: 48px;
  z-index: 0;
  font-weight: 300;
}
footer aio-footer {
  position: relative;
  z-index: 0;
}
footer .footer-block {
  margin: 0 24px;
  vertical-align: top;
}
footer a {
  font-weight: 300;
  text-decoration: none;
  z-index: 20;
  position: relative;
}
footer a:hover {
  text-decoration: underline;
}
footer a:visited {
  text-decoration: none;
}
footer a.action {
  cursor: pointer;
}
footer h3 {
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 400;
  margin: 0 0 16px;
}
footer p {
  text-align: center;
  margin: 10px 0px 5px;
}
@media (max-width: 480px) {
  footer p {
    text-align: left;
  }
}
footer div.grid-fluid {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  text-align: left;
  margin: 0 0 40px;
}
footer div.grid-fluid ul {
  list-style-position: inside;
  padding: 0px;
  margin: 0px;
}
footer div.grid-fluid ul li {
  list-style-type: none;
  padding: 0px;
  text-align: left;
}
@media (max-width: 480px) {
  footer div.grid-fluid {
    flex-direction: column;
  }
  footer div.grid-fluid .footer-block {
    margin: 8px 24px;
  }
}
@media (max-width: 700px) {
  footer h3 {
    font-size: 20px;
    line-height: 28px;
  }
}
@media (max-width: 600px) {
  footer h3 {
    font-size: 16px;
    line-height: 24px;
  }
}

footer::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

html, body {
  height: 100%;
}

.clearfix {
  content: "";
  display: table;
  clear: both;
}

.clear {
  clear: both;
}

.l-clearfix:after, .clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.is-visible {
  display: block !important;
}

.l-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.center {
  text-align: center;
}

.visually-hidden {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  min-height: 480px;
  height: 80vh;
  max-height: 480px;
  box-sizing: border-box;
  padding: 48px 48px 32px 48px;
  overflow: hidden;
  transform-origin: 100%;
}
@media (max-width: 480px) {
  .hero {
    max-height: 486px;
    padding-top: 40px;
    transform: none;
  }
}
.hero .hero-title {
  display: inline-block;
  font-size: 28px;
  font-weight: 400;
  line-height: 48px;
  margin: 0 8px 0 0;
  text-transform: uppercase;
}
.hero .hero-title.is-standard-case {
  text-transform: none;
}

.homepage-header {
  background: url("/assets/images/banner.svg");
  background-size: 100%;
}

section#intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 900px;
  height: 480px;
  margin: 0 auto -32px;
  padding: 48px 0 0;
}
@media (max-width: 780px) {
  section#intro {
    justify-content: center;
    width: 100%;
    max-width: 100vw;
    padding: 40px 0 32px;
  }
  section#intro button {
    margin: 0;
    height: 60px;
  }
}
section#intro .homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  margin-top: -7%;
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 780px) {
  section#intro .homepage-container {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
}
section#intro .hero-headline {
  font-size: 40px;
  line-height: 64px;
  font-weight: 500;
  margin: 32px 0 8px 0;
}
section#intro .hero-headline:after {
  display: none;
}
@media (max-width: 780px) {
  section#intro .hero-headline {
    text-align: center;
  }
}
@media (max-width: 575px) {
  section#intro .hero-headline {
    font-size: 32px;
    line-height: 50px;
  }
}
section#intro .hero-sub-headline {
  font-size: 18px;
  line-height: 32px;
}
section#intro .hero-logo {
  display: flex;
  padding-top: 48px;
  padding-bottom: 24px;
}
@media (max-width: 780px) {
  section#intro .hero-logo {
    justify-content: center;
  }
}
section#intro .hero-logo img {
  width: 260px;
  height: 260px;
  margin-bottom: 8px;
  padding: 0;
  filter: drop-shadow(0 2px 2px rgba(10, 16, 20, 0.24));
}
@media (max-width: 780px) {
  section#intro .hero-logo img {
    width: 250px;
    height: 250px;
  }
}

.announcement-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  max-width: 50vw;
  margin: 0 auto;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  box-sizing: border-box;
  transition: all 0.3s ease-in;
}
@media (max-width: 992px) {
  .announcement-bar {
    flex-direction: column;
    text-align: center;
    padding: 32px 16px;
  }
}
@media (max-width: 768px) {
  .announcement-bar {
    width: 100%;
    max-width: none;
  }
}
.announcement-bar > * {
  margin: 8px;
}
.announcement-bar .button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-width: 160px;
  font-size: 16px;
  border-radius: 48px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  box-sizing: border-box;
  cursor: pointer;
}
.announcement-bar .material-icons {
  display: none;
  right: 0;
  position: static;
  transition: all 0.3s ease-in;
  font-size: 16px;
}
.announcement-bar p {
  font-size: 16px;
  margin: 8px;
  text-align: center;
}

.home-row .card {
  height: 70%;
  width: auto;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  box-sizing: border-box;
  transition: box-shadow 0.5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 70%;
  min-width: 350px;
  height: auto;
  margin: auto;
  padding: 24px;
  box-shadow: 0 6px 6px rgba(10, 16, 20, 0.15), 0 0 52px rgba(10, 16, 20, 0.12);
}
.home-row .card:hover {
  box-shadow: 0 8px 8px rgba(10, 16, 20, 0.24), 0 0 8px rgba(10, 16, 20, 0.12);
  text-decoration: none;
}
@media (max-width: 600px) {
  .home-row .card {
    margin: 16px auto 0;
  }
  .home-row .card h2 {
    margin: 0;
  }
  .home-row .card img {
    max-width: none;
    height: 70px;
  }
}
@media (max-width: 1300px) {
  .home-row .card img {
    height: 70px;
    max-width: none;
  }
}
.home-row .card img {
  margin: 16px;
}
.home-row .card .card-text-container {
  margin: 0 16px;
}
.home-row .card .card-text-container p {
  text-align: left;
  margin: 0;
  padding: 8px 0;
}

.button.hero-cta {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 40px;
  padding: 0 24px;
  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
  border-radius: 48px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  box-sizing: border-box;
  cursor: pointer;
}
.button.hero-cta:hover {
  opacity: 0.9;
}

aio-shell.page-resources section, aio-shell.page-events section, aio-shell.page-features section, aio-shell.page-contribute section {
  padding: 0rem 0rem 3rem;
}
aio-shell.page-home section {
  padding: 0;
}
aio-shell.page-home article, aio-shell.page-resources article, aio-shell.page-events article, aio-shell.page-contribute article {
  padding: 32px;
}
@media (max-width: 800px) {
  aio-shell.page-home article, aio-shell.page-resources article, aio-shell.page-events article, aio-shell.page-contribute article {
    padding: 24px;
  }
}
aio-shell.page-features article {
  padding: 0 3rem;
}
@media (max-width: 1300px) {
  aio-shell.page-home .content img, aio-shell.page-resources .content img, aio-shell.page-events .content img, aio-shell.page-features .content img {
    max-width: none;
  }
}
aio-shell.page-home .feature-section img, aio-shell.page-resources .feature-section img, aio-shell.page-events .feature-section img, aio-shell.page-features .feature-section img {
  max-width: 70px;
}
@media (max-width: 600px) {
  aio-shell.page-home mat-sidenav-container.sidenav-container, aio-shell.page-resources mat-sidenav-container.sidenav-container, aio-shell.page-events mat-sidenav-container.sidenav-container, aio-shell.page-features mat-sidenav-container.sidenav-container {
    padding-top: 0;
  }
}

.cta-bar.announcement-bar {
  background: none;
  box-shadow: none;
}

.text-headline {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  text-transform: uppercase;
}

aio-shell:not(.view-SideNav) mat-sidenav-container.sidenav-container {
  max-width: none;
}

div[layout=row] {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
@media (max-width: 480px) {
  div[layout=row] {
    display: block;
  }
}

.layout-row {
  flex-direction: row;
}

.home-rows {
  overflow: hidden;
}
@media (max-width: 600px) {
  .home-rows {
    margin: 0;
  }
}

.background-superhero-paper {
  background-size: 100%;
  background-blend-mode: multiply;
}

.home-row {
  max-width: 920px;
  margin: 32px auto;
}
.home-row .promo-img-container, .home-row .text-container {
  max-width: 50%;
}
@media (max-width: 480px) {
  .home-row .promo-img-container, .home-row .text-container {
    max-width: 100%;
    text-align: center;
  }
  .home-row .promo-img-container:nth-child(even), .home-row .text-container:nth-child(even) {
    flex-direction: column-reverse;
  }
}
.home-row .text-block {
  padding-right: 15%;
}
@media (max-width: 600px) {
  .home-row .text-block {
    padding: 0;
  }
}
.home-row .promo-img-container img {
  max-width: 90% !important;
}
.home-row .promo-img-container p {
  margin: 0 20px;
}
.home-row .promo-img-container img {
  max-width: 90%;
}
@media (max-width: 599px) {
  .home-row .promo-img-container img {
    max-width: 100%;
    float: initial !important;
  }
}

.marketing-banner {
  margin-top: 64px;
  padding: 32px;
}
@media (max-width: 600px) {
  .marketing-banner {
    margin-top: 56px;
    padding: 18px;
  }
}
.marketing-banner .banner-headline {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 300;
  margin: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}
@media (max-width: 600px) {
  .marketing-banner .banner-headline {
    font-size: 18px;
    font-weight: 400;
  }
}
.marketing-banner .banner-headline:after {
  display: none;
}

.page-features .marketing-banner {
  margin-bottom: 20px;
}

aio-shell.page-home {
  line-height: initial;
}
aio-shell.page-home aio-doc-viewer {
  display: block;
  width: 100vw;
  min-height: 100vh;
  background-color: #412945;
  color: white;
}
aio-shell.page-home .mat-drawer-content {
  overflow-x: hidden;
  overflow-y: auto;
}
aio-shell.page-home header {
  background-image: url(/assets/images/header.svg);
  background-size: cover;
  background-position: center center;
  height: 80vh;
  max-height: 800px;
  color: #4b334d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 60px;
}
aio-shell.page-home header img {
  width: auto;
  height: 240px;
  max-height: 30vh;
}
aio-shell.page-home div.title {
  font-size: 60px;
  margin-top: 20px;
  text-transform: uppercase;
}
@media only screen and (max-width: 320px) {
  aio-shell.page-home div.title {
    font-size: 55px;
  }
}
aio-shell.page-home div.subtitle {
  font-size: 30px;
  margin-top: 20px;
}
@media only screen and (max-width: 320px) {
  aio-shell.page-home div.subtitle {
    font-size: 25px;
  }
}
aio-shell.page-home header h2 {
  font-size: 20px;
}
aio-shell.page-home header .cta {
  position: absolute;
  bottom: -22px;
}
aio-shell.page-home button.cta,
aio-shell.page-home a.cta {
  font-size: 18px;
  padding: 6px 28px;
  color: white;
  text-transform: uppercase;
  position: relative;
  height: auto;
}
aio-shell.page-home h2,
aio-shell.page-home p {
  font-family: "Open Sans", sans-serif;
  max-width: 100%;
}
aio-shell.page-home h1,
aio-shell.page-home h3 {
  font-family: "PT Sans", sans-serif;
  text-transform: uppercase;
}
aio-shell.page-home .ngrx-callout {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 60px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-column-gap: 0 20px;
  justify-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
aio-shell.page-home .ngrx-callout:last-of-type {
  border-bottom: none;
}
aio-shell.page-home .ngrx-callout h3 {
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  aio-shell.page-home .ngrx-callout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
aio-shell.page-home .ngrx-callout-description {
  flex-basis: 400px;
  flex-grow: 0;
  flex-shrink: 0;
}
aio-shell.page-home .ngrx-callout-description h3 {
  margin-bottom: 8px;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.82);
}
aio-shell.page-home .ngrx-callout-description p {
  color: rgba(255, 255, 255, 0.82);
}
@media only screen and (max-width: 600px) {
  aio-shell.page-home .ngrx-callout-description {
    flex-basis: initial;
  }
}
@media screen and (min-width: 820px) {
  aio-shell.page-home .ngrx-callout:nth-of-type(2) .ngrx-callout-figure {
    grid-column: 1/2;
    grid-row: 1/2;
    justify-self: start;
  }
  aio-shell.page-home .ngrx-callout:nth-of-type(2) .ngrx-callout-description {
    grid-column: 2/3;
    grid-row: 1/2;
  }
}
@media screen and (max-width: 820px) {
  aio-shell.page-home .ngrx-callout-description {
    padding: 0 20px 30px;
    max-width: 480px;
    text-align: center;
  }
}
aio-shell.page-home .sponsors {
  width: 100vw;
  background-color: white;
  padding: 50px 20px;
  text-align: center;
}
aio-shell.page-home .sponsors h3 {
  color: #412945;
  font-size: 24px;
  margin-bottom: 24px;
}
aio-shell.page-home .final-ngrx-callout {
  background-image: url(/assets/images/header.svg);
  background-size: cover;
  background-position: bottom center;
  height: 180px;
  color: #4b334d;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}
aio-shell.page-home .final-ngrx-callout h3 {
  font-size: 20px;
}
aio-shell.page-home .final-ngrx-callout .content {
  max-width: 960px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
aio-shell.page-home .final-ngrx-callout .cta {
  flex-shrink: 0;
}

#file-not-found {
  padding: 3rem 3rem 3rem;
}

.nf-container {
  align-items: center;
  padding: 32px;
}

.nf-response {
  margin: 32px;
  height: 100%;
  flex-direction: column;
}
.nf-response h1 {
  font-size: 48px;
  text-transform: uppercase;
  margin: 8px 0;
}

.nf-icon.material-icons {
  font-size: 100px;
  position: static;
}

.starting.mat-drawer-transition .mat-drawer-content {
  transition: none;
}

aio-nav-menu {
  display: block;
  margin: 0 auto;
  max-width: 268px;
  font-size: 14px;
  line-height: 20px;
}
aio-nav-menu ul, aio-nav-menu a {
  padding: 0;
  margin: 0;
}
aio-nav-menu:first-child {
  margin-top: 16px;
}
aio-nav-menu aio-nav-item div a {
  padding-left: 6px;
}

mat-sidenav.mat-sidenav.sidenav {
  position: fixed;
  top: 64px;
  bottom: 0;
  left: 0;
  padding: 0;
  min-width: 260px;
  box-shadow: 6px 0 6px rgba(0, 0, 0, 0.1);
}
mat-sidenav.mat-sidenav.sidenav.collapsed {
  top: 56px;
}

mat-sidenav-container.sidenav-container {
  min-height: 100%;
  height: auto !important;
  max-width: 100%;
  margin: 0;
  transform: none;
}
mat-sidenav-container.sidenav-container.has-floating-toc {
  max-width: 82%;
}

mat-sidenav-container div.mat-sidenav-content {
  height: auto;
}

.heading-container {
  display: flex;
  flex-direction: column;
}

.vertical-menu-item {
  box-sizing: border-box;
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 260px;
  overflow-wrap: break-word;
  padding-top: 4px;
  padding-bottom: 4px;
  text-decoration: none;
  text-align: left;
  word-wrap: break-word;
}
.vertical-menu-item:focus {
  outline: #1E88E5 auto 2px;
}
.vertical-menu-item .node-title {
  flex: 1;
}
.vertical-menu-item .mat-icon {
  margin: 4px;
}

.vertical-menu-item.selected {
  font-weight: 500;
}

button.vertical-menu-item {
  border: none;
  background-color: transparent;
  margin-right: 0;
}

.heading {
  cursor: pointer;
  position: relative;
}

.heading-children.expanded {
  visibility: visible;
  opacity: 1;
  max-height: 4000px;
  transition: visibility 500ms, opacity 500ms, max-height 500ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.heading-children.collapsed {
  visibility: hidden;
  opacity: 0;
  max-height: 1px;
  transition: visibility 275ms, opacity 275ms, max-height 280ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.no-animations .heading-children.expanded, .no-animations .heading-children.collapsed {
  transition: none !important;
}

.level-1 {
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-left: 14px;
  transition: background-color 0.2s;
}

.level-2 {
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-left: 12px;
  text-transform: none;
}

.level-3 {
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
}

.level-4 {
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-left: 8px;
}

.level-1.expanded .mat-icon, .level-2.expanded .mat-icon {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
}

.level-1:not(.expanded) .mat-icon, .level-2:not(.expanded) .mat-icon {
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
}

aio-nav-menu.top-menu {
  padding: 24px 0 0;
}
aio-nav-menu.top-menu aio-nav-item:last-child div {
  border-bottom: 1px solid rgba(110, 110, 110, 0.5);
}
aio-nav-menu.top-menu aio-nav-item:first-child div {
  border-top: 1px solid rgba(110, 110, 110, 0.5);
}

mat-sidenav .doc-version {
  padding: 8px;
  border-top: 1px solid #DBDBDB;
}
mat-sidenav .doc-version select {
  outline: none;
  width: 100%;
  height: 32px;
  border: 1px solid #DBDBDB;
}
mat-sidenav .doc-version select option {
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 16px;
  line-height: 24px;
}

nav#main-table-of-contents {
  width: 200px;
  height: 900px;
  position: fixed;
  right: 0;
  top: 50px;
  bottom: 100px;
  margin-left: 32px;
  background-color: #412846;
}

mat-toolbar.mat-toolbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
}
mat-toolbar.mat-toolbar mat-toolbar-row {
  padding: 0 16px 0 0;
}

@media (min-width: 481px) {
  aio-shell.page-home mat-toolbar.mat-toolbar:not(.transitioning) {
    background-color: transparent;
    transition: background-color 0.2s linear;
  }
}

aio-shell.page-home mat-toolbar.mat-toolbar,
aio-shell.page-features mat-toolbar.mat-toolbar,
aio-shell.page-events mat-toolbar.mat-toolbar,
aio-shell.page-resources mat-toolbar.mat-toolbar {
  box-shadow: none;
}
@media (min-width: 481px) {
  aio-shell.page-home mat-toolbar.mat-toolbar,
  aio-shell.page-features mat-toolbar.mat-toolbar,
  aio-shell.page-events mat-toolbar.mat-toolbar,
  aio-shell.page-resources mat-toolbar.mat-toolbar {
    position: absolute;
  }
}

@media (min-width: 1366px) {
  aio-shell.folder-api mat-toolbar.mat-toolbar .hamburger.mat-mdc-button,
  aio-shell.folder-docs mat-toolbar.mat-toolbar .hamburger.mat-mdc-button,
  aio-shell.folder-guide mat-toolbar.mat-toolbar .hamburger.mat-mdc-button,
  aio-shell.folder-tutorial mat-toolbar.mat-toolbar .hamburger.mat-mdc-button {
    margin: 0 8px 0 0;
  }
}

.hamburger.mat-mdc-button {
  height: 100%;
  margin: 0 8px 0 0;
  padding: 0;
}
@media (min-width: 1366px) {
  .hamburger.mat-mdc-button {
    margin: 0 16px 0 -64px;
  }
}
@media (max-width: 480px) {
  .hamburger.mat-mdc-button {
    min-width: 15%;
  }
}
.hamburger.mat-mdc-button:not(.starting) {
  transition-duration: 0.4s;
  transition-property: color, margin;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}
.hamburger.mat-mdc-button > .mat-icon {
  position: inherit;
  font-size: 24px;
  width: 24px;
  height: 24px;
}

.nav-link.home {
  cursor: pointer;
  margin: 0 16px 0 0;
  padding: 21px 0;
}
@media screen and (max-width: 480px) {
  .nav-link.home {
    margin-right: 8px;
  }
}
.nav-link.home img {
  position: relative;
  margin-top: -21px;
  top: 12px;
  height: 40px;
}
@media (max-width: 1366px) {
  .nav-link.home img:hover {
    transform: scale(1.1);
  }
}

.nav-link[href=workshops] {
  border-radius: 10px;
  position: relative;
  padding: 16px;
}
.nav-link[href=workshops]:focus {
  padding: 16px;
}
.nav-link[href=workshops]:after {
  content: "New";
  position: absolute;
  font-size: 12px;
  line-height: 14px;
  top: 4px;
  right: 10px;
}

aio-top-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
aio-top-menu ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-position: inside;
  padding: 0px;
  margin: 0px;
}
aio-top-menu ul li {
  padding-bottom: 2px;
  list-style-type: none;
  cursor: pointer;
}
aio-top-menu ul li:hover {
  opacity: 0.7;
}
aio-top-menu ul li:focus {
  outline: none;
}
aio-top-menu a.nav-link {
  margin: 0;
  padding: 24px 16px;
  cursor: pointer;
}
aio-top-menu a.nav-link:focus {
  border-radius: 4px;
  outline: none;
  padding: 8px 16px;
}

aio-search-box.search-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-width: 150px;
  height: 100%;
}
aio-search-box.search-container input {
  border: none;
  border-radius: 100px;
  padding: 5px 16px;
  margin-left: 8px;
  width: 180px;
  max-width: 240px;
  height: 50%;
  -webkit-appearance: none;
}
aio-search-box.search-container input:focus {
  outline: none;
}
@media (min-width: 1000px) {
  aio-search-box.search-container input {
    transition: width 0.4s ease-in-out;
  }
  aio-search-box.search-container input:focus {
    width: 500px;
  }
}
@media (max-width: 480px) {
  aio-search-box.search-container input {
    width: 150px;
  }
}

.app-toolbar .toolbar-external-icons-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.app-toolbar .toolbar-external-icons-container a {
  display: flex;
  align-items: center;
  margin-left: 16px;
}
@media screen and (max-width: 480px) {
  .app-toolbar .toolbar-external-icons-container a {
    margin-left: 8px;
  }
}
.app-toolbar .toolbar-external-icons-container a:hover {
  opacity: 0.8;
}
.app-toolbar .toolbar-external-icons-container a img {
  height: 24px;
}

/* ==============================
   MODULE STYLES
   ============================== */
.alert, .callout {
  padding: 16px;
  margin: 24px 0px;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  box-sizing: border-box;
  clear: both;
}
.alert h1, .callout h1, .alert h2, .callout h2, .alert h3, .callout h3, .alert h4, .callout h4, .alert h5, .callout h5, .alert h6, .callout h6 {
  font-weight: 500;
}
.alert > *, .callout > * {
  margin: 8px 16px;
}

.github-links {
  float: right;
}
.github-links .material-icons {
  border-radius: 4px;
  padding: 4px;
  font-size: 20px;
  line-height: 28px;
}

.api-header {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .api-header {
    flex-direction: column;
    align-items: flex-start;
  }
}

.api-body .class-overview {
  position: relative;
}
.api-body .class-overview code-example {
  clear: left;
}
.api-body .method-table .with-github-links, .api-body .option-table .with-github-links, .api-body .list-table .with-github-links {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.api-body .method-table .with-github-links .github-links a .material-icons:hover, .api-body .option-table .with-github-links .github-links a .material-icons:hover, .api-body .list-table .with-github-links .github-links a .material-icons:hover {
  background: none;
}
.api-body .method-table h3, .api-body .option-table h3, .api-body .list-table h3 {
  margin: 6px 0;
  font-weight: bold;
  clear: left;
}
.api-body .method-table h4, .api-body .option-table h4, .api-body .list-table h4 {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  margin-top: 12px;
}
.api-body .api-heading {
  padding: 5px 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
.api-body .short-description {
  margin: 6px 0 0 10px;
}
.api-body .properties-table {
  font-size: 14px;
  line-height: 20px;
}
.api-body .properties-table thead th:nth-child(1) {
  width: 20%;
}
.api-body .properties-table thead th:nth-child(2) {
  width: 20%;
}
.api-body .parameters-table {
  margin-top: 0;
  font-size: 14px;
  line-height: 20px;
}
.api-body .parameters-table td:nth-child(1) {
  width: 20%;
}
.api-body details.overloads {
  margin-left: -8px;
}
.api-body details.overloads summary {
  height: inherit;
  padding: 8px 12px;
}
.api-body details.overloads summary h4 {
  margin: 0;
  clear: left;
}
.api-body .from-constructor, .api-body .read-only-property {
  font-style: italic;
}

.deprecated-api-item {
  text-decoration: line-through;
}

/* API EDIT ICON */
#api .api-filter .material-icons {
  right: 48px;
}

/* API LIST STYLES */
aio-api-list div.form-search i.material-icons {
  width: 20px;
  pointer-events: none;
}
aio-api-list .form-search input {
  width: 182px;
}
aio-api-list .api-list-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
aio-api-list .api-list-container h2 {
  margin-top: 16px;
}

.api-filter {
  display: flex;
  margin: 0 auto;
}
@media (max-width: 600px) {
  .api-filter {
    flex-direction: column;
    margin: 16px auto;
  }
}
.api-filter .form-select-menu, .api-filter .form-search {
  margin: 8px;
}

/* LAYOUT */
.docs-content {
  position: relative;
}

.l-content-small {
  padding: 16px;
  max-width: 1100px;
  margin: 0;
}
@media handheld and (max-width: 480px), screen and (max-device-width: 480px), screen and (max-width: 800px) {
  .l-content-small {
    padding: 24px 0 0;
  }
}

/* SEARCH BAR */
.form-search {
  position: relative;
}
.form-search input {
  font-size: 16px;
  line-height: 24px;
  height: 32px;
  line-height: 32px;
  outline: none;
  padding: 0 16px 0 32px;
  transition: all 0.2s;
}
.form-search input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 14px;
  line-height: 20px;
}
.form-search input::-moz-placeholder { /* Firefox 19+ */
  font-size: 14px;
  line-height: 20px;
}
.form-search input:-ms-input-placeholder { /* IE 10+ */
  font-size: 14px;
  line-height: 20px;
}
.form-search input:-moz-placeholder { /* Firefox 18- */
  font-size: 14px;
  line-height: 20px;
}
.form-search .material-icons {
  font-size: 20px;
  line-height: 28px;
  line-height: 32px;
  height: 100%;
  left: 8px;
  position: absolute;
  z-index: 1;
}

/* API SYMBOLS */
/* SYMBOL CLASS */
.symbol {
  border-radius: 2px;
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  width: 16px;
}
.symbol.all:before {
  content: " ";
}
.symbol.decorator:before {
  content: "@";
}
.symbol.directive:before {
  content: "D";
}
.symbol.pipe:before {
  content: "P";
}
.symbol.class:before {
  content: "C";
}
.symbol.interface:before {
  content: "I";
}
.symbol.function:before {
  content: "F";
}
.symbol.enum:before {
  content: "E";
}
.symbol.const:before {
  content: "K";
}
.symbol.let:before {
  content: "K";
}
.symbol.var:before {
  content: "K";
}
.symbol.ngmodule:before {
  content: "M";
}
.symbol.type-alias:before {
  content: "T";
}
.symbol.package:before {
  content: "Pk";
}

/* API HOMEE PAGE */
/* API FILTER MENU */
.api-filter aio-select {
  width: 200px;
}
.api-filter aio-select .symbol {
  margin-right: 8px;
}

/* API CLASS LIST */
.docs-content .api-list {
  list-style: none;
  margin: 0 0 32px -8px;
  padding: 0;
  overflow: hidden;
}
@media screen and (max-width: 600px) {
  .docs-content .api-list {
    margin: 0 0 0 -8px;
  }
}
.docs-content .api-list li {
  font-size: 14px;
  margin: 8px 0;
  line-height: 14px;
  padding: 0;
  float: left;
  width: 33%;
  overflow: hidden;
  min-width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.docs-content .api-list li .symbol {
  margin-right: 8px;
}
.docs-content .api-list li a {
  display: inline-block;
  line-height: 16px;
  padding: 0 16px 0;
  text-decoration: none;
  transition: all 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
}

.docs-content .h2-api-docs,
.docs-content .h2-api-docs:first-of-type {
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
}

.openParens {
  margin-top: 15px;
}

.endParens {
  margin-bottom: 20px !important;
}

p.selector {
  margin: 0;
}
p.location-badge {
  margin: 0 0 16px 16px !important;
}
p .api-doc-code {
  border-bottom: 0;
}
p .api-doc-code :hover {
  border-bottom: none;
}

.row-margin {
  margin-bottom: 36px;
}
.row-margin h2 {
  line-height: 28px;
}

.code-margin {
  margin-bottom: 8px;
}

.no-bg {
  background: none;
  padding: 0;
}

.no-bg-with-indent {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 16px;
  margin-top: 6px;
  margin-bottom: 0;
  background: none;
}

.code-background {
  padding: 0 5px 0;
}

.code-anchor {
  cursor: pointer;
  font-size: inherit;
}
.code-anchor:hover {
  text-decoration: underline;
}

.api-doc-code {
  font-size: 16px;
  line-height: 24px;
}
.api-doc-code.no-pln .pln:last-child {
  display: none;
}

@media screen and (max-width: 600px) {
  .docs-content .layout-xs-column {
    display: block !important;
  }
  .api-doc-code {
    font-size: 12px;
  }
  p.location-badge {
    position: relative;
    font-size: 11px;
  }
}
/* Button Styles */
.button,
a.button.mat-button {
  display: inline-block;
  line-height: 32px;
  padding: 0px 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  border-radius: 3px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  border: none;
}
.button.button-small,
a.button.mat-button.button-small {
  font-size: 14px;
  line-height: 20px;
  line-height: 24px;
  padding: 0px 8px;
}
.button.button-large,
a.button.mat-button.button-large {
  font-size: 16px;
  line-height: 24px;
  line-height: 48px;
  padding: 0px 24px;
}
.button.button-x-large,
a.button.mat-button.button-x-large {
  font-size: 20px;
  line-height: 28px;
  line-height: 56px;
  padding: 0px 24px;
}
.button.button-shield, .button.button-shield.mat-button,
a.button.mat-button.button-shield,
a.button.mat-button.button-shield.mat-button {
  color: rgba(255, 255, 255, 0.87);
  padding-left: 54px;
  background-size: 22px 22px;
}

.cta-bar {
  text-align: center;
}
.cta-bar .button {
  margin: 0px 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  transition: all 0.2s ease-in-out;
}
.cta-bar .button:hover {
  transform: scale(1.1);
}

a.filter-button {
  width: 140px;
  font-size: 16px;
  line-height: 24px;
  line-height: 48px;
  padding: 0px 16px;
  margin: 8px;
  border-radius: 4px;
}

[mat-button], [mat-raised-button], [mat-button], [mat-raised-button] {
  text-transform: uppercase;
}

/* ==============================
   MODULE STYLES
   ============================== */
.callout {
  padding: 0px;
  border-left: none !important;
  border-radius: 4px;
}
.callout header {
  line-height: 24px;
  font-weight: 500;
  padding: 8px 16px;
  margin: 0;
  text-transform: uppercase;
  border-radius: 4px 4px 0 0;
}
.callout p {
  padding: 16px;
  margin: 0px;
  font-size: 16px;
  line-height: 24px;
}

.card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 24px 0;
}

.docs-card {
  height: 194px;
  width: 30%;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  box-sizing: border-box;
  transition: box-shadow 0.5s;
  max-width: 340px;
  min-width: 262px;
  margin: 24px 8px;
  padding-bottom: 48px;
  position: relative;
}
.docs-card:hover {
  box-shadow: 0 8px 8px rgba(10, 16, 20, 0.24), 0 0 8px rgba(10, 16, 20, 0.12);
  text-decoration: none;
}
.docs-card:hover {
  text-decoration: none;
}
.docs-card:hover p {
  padding: 0 16px;
}
.docs-card:hover .card-footer {
  line-height: 32px;
  padding: 8px 16px;
}
.docs-card section {
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  padding: 32px 0 24px;
  text-transform: none;
  text-align: center;
}
.docs-card p {
  font-size: 14px;
  line-height: 20px;
  padding: 0 16px;
  margin: 0;
  text-align: center;
}
.docs-card .card-footer {
  bottom: 0;
  box-sizing: border-box;
  line-height: 48px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: right;
}
.docs-card .card-footer a {
  font-size: 14px;
  line-height: 20px;
}
.docs-card .card-footer.center {
  text-align: center;
}

.card-section {
  height: auto;
  width: 90%;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  box-sizing: border-box;
  transition: box-shadow 0.5s;
  padding: 16px 32px;
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card-section:hover {
  box-shadow: 0 8px 8px rgba(10, 16, 20, 0.24), 0 0 8px rgba(10, 16, 20, 0.12);
  text-decoration: none;
}
.card-section h1, .card-section h2, .card-section h3, .card-section h4, .card-section h5, .card-section h6 {
  margin: 8px 0;
}
.card-section a, .card-section .button, .card-section button {
  text-align: center;
}

code-example,
code-tabs {
  clear: both;
  display: block;
}

code-example:not(.no-box) {
  border-radius: 5px;
  margin: 16px auto;
}
code-example.no-box pre {
  margin: 0;
}
code-example.no-box code {
  background-color: transparent;
}
code-example code {
  overflow: auto;
}

code-example .mat-card,
code-tabs .mat-card {
  padding: 0;
  border-radius: 5px;
}
code-example code,
code-tabs code {
  overflow: auto;
}

code-example header {
  border-radius: 5px 5px 0 0;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 16px;
}

code-tabs div .mat-tab-body-content {
  height: auto;
}

code-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body {
  overflow-y: hidden;
}

code-tabs mat-tab-body-content .fadeIn {
  animation: opacity 2s ease-in;
}

aio-code pre {
  display: flex;
  min-height: 32px;
  margin: 16px 24px;
  white-space: pre-wrap;
  align-items: center;
}
aio-code pre code span {
  line-height: 24px;
}

.copy-button {
  position: absolute;
  top: -7px;
  right: -19px;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.code-tab-group .mat-tab-label {
  white-space: nowrap;
}

.code-tab-group .mat-tab-body-content {
  height: auto;
  transform: none;
}

[role=tabpanel] {
  transition: none;
}

.sidenav-content code a {
  color: inherit;
  font-size: inherit;
}

/* PRETTY PRINTING STYLES for prettify.js. */
.prettyprint {
  position: relative;
}

/* Specify class=linenums on a pre to get line numbering */
ol.linenums {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
ol.linenums li {
  margin: 0;
  font-family: "Droid Sans Mono", monospace;
  font-size: 90%;
  line-height: 24px;
}

.contribute-container h2 {
  margin: 0;
}
.contribute-container .card-section {
  justify-content: space-between;
  max-width: 880px;
}
.contribute-container .card-section > :first-child {
  margin-right: 2rem;
  width: 60%;
}
.contribute-container .card-section:last-child {
  margin-bottom: 0;
}

@media handheld and (max-width: 480px), screen and (max-width: 480px), screen and (max-width: 900px) {
  aio-contributor-list .grid-fluid {
    width: auto;
  }
}
@media handheld and (max-width: 480px), screen and (max-width: 480px), screen and (max-width: 900px) {
  aio-contributor-list .grid-fluid {
    margin-left: 20px;
    margin-right: 20px;
    float: none;
    display: block;
    width: auto;
  }
}

.group-buttons {
  margin: 32px auto;
}

.contributor-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

aio-contributor, ngrx-contributor {
  margin: 8px;
  position: relative;
  border-radius: 4px;
  transition: all 0.3s;
  perspective: 800px;
  max-width: 270px;
}
aio-contributor.clickable, ngrx-contributor.clickable {
  cursor: pointer;
}
aio-contributor:hover, ngrx-contributor:hover {
  transform: translate3d(0, -3px, 0);
}
aio-contributor:hover .contributor-image, ngrx-contributor:hover .contributor-image {
  transform: scale(1.05);
}
aio-contributor:hover .contributor-info, ngrx-contributor:hover .contributor-info {
  opacity: 1;
}
aio-contributor .contributor-info, ngrx-contributor .contributor-info {
  height: 168px;
  width: 168px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  border-radius: 50%;
}
aio-contributor .contributor-info [mat-button], ngrx-contributor .contributor-info [mat-button] {
  font-size: 14px;
  font-weight: 500;
  margin: 8px;
  padding: 0;
}
aio-contributor .contributor-info [mat-button].icon, ngrx-contributor .contributor-info [mat-button].icon {
  min-width: 20px;
  width: 20px;
}
aio-contributor .contributor-info [mat-button].icon .fa-2x, ngrx-contributor .contributor-info [mat-button].icon .fa-2x {
  font-size: 20px;
}
aio-contributor div.contributor-card, ngrx-contributor div.contributor-card {
  width: 250px;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  transform-style: preserve-3d;
  transition: transform ease 500ms;
}
aio-contributor div.contributor-card h3, ngrx-contributor div.contributor-card h3 {
  margin: 8px 0;
}
aio-contributor div.contributor-card .card-front, aio-contributor div.contributor-card .card-back, ngrx-contributor div.contributor-card .card-front, ngrx-contributor div.contributor-card .card-back {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
aio-contributor div.contributor-card .card-front, ngrx-contributor div.contributor-card .card-front {
  justify-content: center;
}
aio-contributor div.contributor-card .card-back, ngrx-contributor div.contributor-card .card-back {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 24px;
  transform: rotateY(180deg);
}
aio-contributor div.contributor-card .card-back section, ngrx-contributor div.contributor-card .card-back section {
  display: none;
}
aio-contributor div.contributor-card .card-back p, ngrx-contributor div.contributor-card .card-back p {
  margin: 8px 0;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  overflow: hidden;
}
aio-contributor div.contributor-card.flipped, ngrx-contributor div.contributor-card.flipped {
  transform: rotateY(180deg);
}
aio-contributor div.contributor-card.flipped .card-front, ngrx-contributor div.contributor-card.flipped .card-front {
  display: none;
}
aio-contributor .contributor-image, ngrx-contributor .contributor-image {
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  height: 168px;
  width: 168px;
  background-size: cover;
  background-position: center;
  margin: 8px auto;
  transition: all 0.2s ease-in-out;
}
aio-contributor section, ngrx-contributor section {
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
aio-contributor p, ngrx-contributor p {
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  margin: 8px 16px;
  text-overflow: ellipsis;
  overflow: scroll;
  font-weight: 400;
}

/*
 * General styling to make detail/summary tags look a bit more material
 * To get the best out of it you should structure your usage like this:
 *
 * ```
 * <details>
 *   <summary>Some title</summary>
 *   <div class="details-content">
 *     Some content
 *   </div>
 *  </details>
 *
 */
summary {
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  padding: 16px 24px;
  display: block;
}
summary::-webkit-details-marker {
  display: none;
}
summary::before {
  content: "\e5ce";
  font-family: "Material Icons";
  font-size: 24px;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
  float: right;
}

details .detail-contents, details p {
  padding: 0px 24px 16px 24px;
}
details code-example {
  padding: 0;
}
details[open] > summary::before {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
}

.edit-page-cta {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  margin-right: 32px;
  display: block;
  position: absolute;
  right: 0;
}

.feature-section {
  margin: 0 0 32px;
}
.feature-section .feature-header, .feature-section .text-headline {
  text-align: center;
}
.feature-section .feature-header img {
  margin: 16px;
}
.feature-section .feature-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 8px 0px;
  clear: both;
}
.feature-section .feature-row {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 600px) {
  .feature-section .feature-row {
    flex-direction: column;
  }
}
.feature-section .feature-row .feature {
  max-width: 300px;
  margin: 0 16px;
}
@media (max-width: 768px) {
  .feature-section .feature-row .feature {
    max-width: 100%;
  }
}

.filetree {
  border-radius: 4px;
  margin: 0 0 24px 0;
  padding: 16px 32px;
}
.filetree .file {
  display: block;
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.3px;
  line-height: 32px;
}
.filetree .children {
  padding-left: 24px;
  position: relative;
  overflow: hidden;
}
.filetree .children .file {
  position: relative;
}
.filetree .children .file:before {
  content: "";
  left: -18px;
  bottom: 16px;
  width: 16px;
  height: 9999px;
  position: absolute;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-radius: 0 0 0 3px;
}

.sidenav-content h1 .header-link, .sidenav-content h2 .header-link, .sidenav-content h3 .header-link, .sidenav-content h4 .header-link, .sidenav-content h5 .header-link, .sidenav-content h6 .header-link {
  margin: 0 4px;
  text-decoration: none;
  user-select: none;
  visibility: hidden;
  display: inline-block;
  vertical-align: text-top;
  width: 1px;
}
.sidenav-content h1:hover .header-link, .sidenav-content h2:hover .header-link, .sidenav-content h3:hover .header-link, .sidenav-content h4:hover .header-link, .sidenav-content h5:hover .header-link, .sidenav-content h6:hover .header-link {
  visibility: visible;
}

hr {
  border: none;
  height: 1px;
}

.hr-margin {
  display: block;
  height: 1px;
  border: 0;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 0;
}

.content img.right {
  clear: both;
  float: right;
  margin-left: 20px;
  margin-bottom: 20px;
}
.content img.left {
  clear: both;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}
@media (max-width: 1300px) {
  .content img {
    max-width: 100%;
    height: auto;
  }
}
@media (max-width: 600px) {
  .content img {
    float: none !important;
  }
  .content img.right {
    margin-left: 0;
  }
  .content img.left {
    margin-right: 0;
  }
}
.content figure {
  border-radius: 4px;
  padding: 20px;
  display: inline-block;
  margin: 0 0 14px 0;
}
.content figure img {
  border-radius: 4px;
}

.progress-bar-container {
  height: 2px;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 11;
}

table {
  margin: 24px 0px;
  border-radius: 2px;
}
table.is-full-width {
  width: 100%;
}
table.is-fixed-layout {
  table-layout: fixed;
}
table thead > tr {
  vertical-align: inherit;
  border-color: inherit;
}
table thead > tr > th {
  font-size: 14px;
  line-height: 20px;
  line-height: 28px;
  font-weight: 500;
  padding: 8px 24px;
  text-align: left;
  text-transform: uppercase;
}
table tbody > tr > th,
table tbody > tr > td {
  padding: 16px;
  text-align: left;
  line-height: 24px;
  vertical-align: top;
}
@media (max-width: 480px) {
  table tbody > tr > th:before,
  table tbody > tr > td:before {
    display: inline-block;
  }
}
table tbody > tr > td {
  letter-spacing: 0.3px;
}
table tbody > tr > th {
  font-weight: 600;
  max-width: 100px;
}
table tbody > tr:last-child td {
  border: none;
}

#cheatsheet table tbody td {
  overflow: auto;
}
@media only screen and (max-width: 990px) {
  #cheatsheet {
    /* Force table to not be like tables anymore */
  }
  #cheatsheet table,
  #cheatsheet thead,
  #cheatsheet tbody,
  #cheatsheet tfoot,
  #cheatsheet tr,
  #cheatsheet th,
  #cheatsheet td {
    display: block;
    position: relative;
    max-width: 100%;
  }
  #cheatsheet table code,
  #cheatsheet thead code,
  #cheatsheet tbody code,
  #cheatsheet tfoot code,
  #cheatsheet tr code,
  #cheatsheet th code,
  #cheatsheet td code {
    padding: 0;
    background-color: inherit;
  }
  #cheatsheet th {
    border-right: none;
  }
  #cheatsheet th:not(:last-child),
  #cheatsheet td:not(:last-child) {
    border-bottom: none;
    padding-bottom: 0px;
  }
}

.presskit-container {
  padding: 0 32px 32px 32px;
}
.presskit-container .l-space-left-3 {
  margin-left: 24px;
}
.presskit-container .cc-by-anchor {
  text-decoration: underline;
}
.presskit-container .presskit-row {
  margin: 48px 0;
  width: 100%;
}
.presskit-container .presskit-row .presskit-inner {
  display: flex;
  align-items: center;
}
@media (max-width: 599px) {
  .presskit-container .presskit-row .presskit-inner {
    flex-direction: column;
  }
}
.presskit-container .presskit-row .presskit-inner h3 {
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 599px) {
  .presskit-container .presskit-row .presskit-inner h3 {
    padding-bottom: 16px;
  }
}
.presskit-container .presskit-row .presskit-inner .transparent-img-bg {
  margin-top: 10px;
  border-radius: 4px;
  width: 128px;
  height: 128px;
}
.presskit-container .presskit-row .presskit-inner ul {
  padding: 0;
  list-style-type: none;
}
@media (max-width: 599px) {
  .presskit-container .presskit-row .presskit-inner ul {
    padding: 0 !important;
    margin: 0 !important;
  }
}
.presskit-container .presskit-row .presskit-inner ul li {
  margin: 0 0 8px 0;
}
@media (max-width: 599px) {
  .presskit-container .presskit-row .presskit-image-container {
    text-align: center;
  }
}
.presskit-container .presskit-row .presskit-image-container img {
  height: 128px;
  width: auto;
  margin-bottom: 16px;
}
.presskit-container .presskit-row:first-child {
  margin-top: 0;
}
@media (max-width: 599px) {
  .presskit-container .presskit-row:first-child {
    margin-top: 48px;
  }
}

.showcase {
  width: 80%;
}

.c-resource-nav {
  width: 20%;
}

.resources-container {
  position: relative;
}

.grid-fixed:after, .grid-fixed:before {
  content: ".";
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0;
}

@media handheld and (max-width: 480px), screen and (max-width: 480px), screen and (max-width: 900px) {
  .grid-fixed {
    width: auto;
  }
}
@media handheld and (max-width: 480px), screen and (max-width: 480px), screen and (max-width: 900px) {
  .grid-fixed .c3, .grid-fixed .c8 {
    margin-left: 20px;
    margin-right: 20px;
    float: none;
    display: block;
    width: auto;
  }
}
@media handheld and (max-width: 480px), screen and (max-width: 480px), screen and (max-width: 480px) {
  .grid-fixed .c3, .grid-fixed .c8 {
    margin-left: 0px;
    margin-right: 0px;
    float: none;
    display: block;
    width: auto;
  }
}
@media handheld and (max-width: 900px), screen and (max-width: 900px) {
  /* line 6, ../scss/_responsive.scss */
  .grid-fixed {
    margin: 0 auto;
    *zoom: 1;
  }
  .grid-fixed:after, .grid-fixed:before {
    content: ".";
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0;
  }
}
@media handheld and (max-width: 480px), screen and (max-width: 480px) {
  /* line 6, ../scss/_responsive.scss */
  .grid-fixed {
    margin: 0 auto;
    *zoom: 1;
  }
  .grid-fixed:after, .grid-fixed:before {
    content: ".";
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0;
  }
}
aio-resource-list .shadow-1 {
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
aio-resource-list .showcase {
  margin-bottom: 48px;
  border-radius: 4px;
}
aio-resource-list .c-resource h4 {
  margin: 0;
  line-height: 24px;
}
aio-resource-list .c-resource p {
  margin: 0;
}
aio-resource-list .c-resource-nav {
  position: fixed;
  top: 142px;
  right: 32px;
  width: 160px;
  z-index: 1;
  border-radius: 2px;
}
aio-resource-list .c-resource-nav a {
  text-decoration: none;
}
aio-resource-list .c-resource-nav .category {
  padding: 10px 0;
}
aio-resource-list .c-resource-nav .category .category-link {
  display: block;
  margin: 2px 0;
  padding: 3px 14px;
  font-size: 18px !important;
}
aio-resource-list .c-resource-nav .subcategory .subcategory-link {
  display: block;
  margin: 2px 0;
  padding: 4px 14px;
}
aio-resource-list .h-anchor-offset {
  display: block;
  position: relative;
  top: -20px;
  visibility: hidden;
}
aio-resource-list .l-flex--column {
  display: flex;
  flex-direction: column;
}
aio-resource-list .c-resource-header {
  margin-bottom: 16px;
}
aio-resource-list .c-contribute {
  margin-bottom: 24px;
}
aio-resource-list .c-resource-header h2 {
  margin: 0;
}
aio-resource-list .subcategory-title {
  padding: 16px 23px;
  margin: 0;
}
aio-resource-list .h-capitalize {
  text-transform: capitalize;
}
aio-resource-list .h-hide {
  display: none;
}
aio-resource-list .resource-row-link {
  border: transparent solid 1px;
  margin: 0;
  padding: 16px 23px 16px 23px;
  position: relative;
  text-decoration: none;
  transition: all 0.3s;
}
aio-resource-list .resource-row-link:hover {
  text-decoration: none;
  border-radius: 4px;
  transform: translateY(-2px);
}
@media (max-width: 900px) {
  aio-resource-list .c-resource-nav {
    display: none;
  }
}

aio-search-results {
  z-index: 10;
}

.search-results {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  overflow: auto;
  padding: 68px 32px 0;
  width: auto;
  max-height: 95vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  box-sizing: border-box;
}
@media (max-width: 480px) {
  .search-results {
    display: block;
  }
  .search-results .search-area {
    display: block;
    margin: 16px 16px;
  }
}

aio-search-results.embedded .search-results {
  padding: 0;
  color: inherit;
  width: auto;
  max-height: 100%;
  position: relative;
  background-color: inherit;
  box-shadow: none;
  box-sizing: border-box;
}

.search-area {
  display: flex;
  flex-direction: column;
  margin: 16px 16px;
  height: 100%;
}
.search-area h3 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  margin: 10px 0px 5px;
  text-transform: uppercase;
}
.search-area ul {
  margin: 0;
  padding: 0;
}
.search-area ul li {
  list-style: none;
}
.search-area a {
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  font-weight: normal;
}
.search-area a:visited {
  text-decoration: none;
}
.search-area a span.symbol {
  margin-right: 8px;
}
.search-area .priority-pages {
  padding: 0.5rem 0;
}
.search-area .priority-pages a {
  font-weight: bold;
}
@media (min-width: 600px) {
  .search-area {
    display: block;
  }
}

.toc-container {
  width: 18%;
  position: fixed;
  top: 76px;
  right: 0;
  bottom: 12px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (min-width: 801px) {
  aio-toc.embedded {
    display: none;
  }
}
aio-toc.embedded .toc-inner {
  padding: 12px 0 0 0;
}
aio-toc.embedded .toc-inner .toc-heading {
  margin: 0 0 8px;
}

.toc-inner {
  font-size: 13px;
  overflow-y: visible;
  padding: 4px 0 0 10px;
}
.toc-inner .toc-heading,
.toc-inner .toc-list .h1 {
  font-size: 115%;
}
.toc-inner .toc-heading {
  font-weight: 500;
  margin: 0 0 16px 8px;
  padding: 0;
}
.toc-inner .toc-heading.secondary {
  position: relative;
  top: -8px;
}
.toc-inner button.toc-heading,
.toc-inner button.toc-more-items {
  cursor: pointer;
  display: inline-block;
  background: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  text-align: start;
}
.toc-inner button.toc-heading.embedded:focus,
.toc-inner button.toc-more-items.embedded:focus {
  outline: none;
}
.toc-inner button.toc-heading mat-icon.rotating-icon {
  height: 18px;
  width: 18px;
  position: relative;
  left: -4px;
  top: 5px;
}
.toc-inner button.toc-more-items {
  top: 10px;
  position: relative;
}
.toc-inner button.toc-more-items::after {
  content: "expand_less";
}
.toc-inner button.toc-more-items.collapsed::after {
  content: "more_horiz";
}
.toc-inner .mat-icon.collapsed {
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
}
.toc-inner .mat-icon:not(.collapsed) {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
}
.toc-inner ul.toc-list {
  list-style-type: none;
  margin: 0;
  padding: 0 8px 0 0;
}
@media (max-width: 800px) {
  .toc-inner ul.toc-list {
    width: auto;
  }
}
.toc-inner ul.toc-list li {
  box-sizing: border-box;
  font-size: 12px;
  line-height: 16px;
  padding: 3px 0 3px 12px;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.toc-inner ul.toc-list li.h1:after {
  content: "";
  display: block;
  height: 1px;
  width: 40%;
  margin: 7px 0 4px 0;
  clear: both;
}
.toc-inner ul.toc-list li.h3 {
  padding-left: 24px;
}
.toc-inner ul.toc-list li a {
  font-size: inherit;
  display: table-cell;
  overflow: visible;
  font-size: 12px;
  display: table-cell;
}
.toc-inner ul.toc-list li.active a {
  font-weight: 500;
}
.toc-inner ul.toc-list li.active a:before {
  content: "";
  border-radius: 50%;
  left: -3px;
  top: 12px;
  position: absolute;
  width: 6px;
  height: 6px;
}
.toc-inner ul.toc-list:not(.embedded) li:before {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
}
.toc-inner ul.toc-list:not(.embedded) li:first-child:before {
  top: 13px;
}
.toc-inner ul.toc-list:not(.embedded) li:last-child:before {
  bottom: calc(100% - 14px);
}
.toc-inner ul.toc-list:not(.embedded) li:not(.active):hover a:before {
  content: "";
  border-radius: 50%;
  left: -3px;
  top: 12px;
  position: absolute;
  width: 6px;
  height: 6px;
}

aio-toc.embedded > div.collapsed li.secondary {
  display: none;
}

/* SELECT MENU */
.form-select-menu {
  position: relative;
}

.form-select-button {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 20px;
  line-height: 32px;
  font-weight: 400;
  height: 32px;
  outline: none;
  padding: 0 16px;
  text-align: left;
  width: 100%;
  cursor: pointer;
}
.form-select-button strong {
  font-weight: 600;
  margin-right: 8px;
  text-transform: uppercase;
}

.form-select-dropdown {
  border-radius: 4px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.form-select-dropdown li {
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  line-height: 32px;
  margin: 0;
  padding: 0 16px 0 40px;
  position: relative;
  transition: all 0.2s;
}
.form-select-dropdown li .symbol {
  left: 16px;
  position: absolute;
  top: 8px;
  z-index: 5;
}

aio-shell.mode-archive .mat-toolbar.mat-primary, aio-shell.mode-archive footer {
  background: linear-gradient(145deg, #263238, #78909C);
}
aio-shell.mode-archive .vertical-menu-item.selected, aio-shell.mode-archive .vertical-menu-item:hover {
  color: #263238;
}
aio-shell.mode-archive .toc-inner ul.toc-list li.active a {
  color: #263238;
}
aio-shell.mode-archive .toc-inner ul.toc-list li.active a:before {
  background-color: #263238;
}
aio-shell.mode-archive .toc-inner ul.toc-list li:hover a {
  color: #263238;
}

aio-shell.mode-next footer {
  background: linear-gradient(145deg, #84438a, #84438a);
}
aio-shell.mode-next .vertical-menu-item.selected, aio-shell.mode-next .vertical-menu-item:hover {
  color: #84438a;
}
aio-shell.mode-next .toc-inner ul.toc-list li.active a {
  color: #84438a;
}
aio-shell.mode-next .toc-inner ul.toc-list li.active a:before {
  background-color: #84438a;
}
aio-shell.mode-next .toc-inner ul.toc-list li:hover a {
  color: #84438a;
}

.mat-toolbar mat-toolbar-row.notification-container {
  padding: 0;
  height: auto;
  overflow: hidden;
}

aio-notification {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 56px;
  justify-content: center;
}
@media (max-width: 430px) {
  aio-notification {
    justify-content: flex-start;
    padding-left: 10px;
  }
}
aio-notification .close-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 56px;
  height: 56px;
}
aio-notification .content {
  max-width: calc(100% - 56px);
  text-transform: none;
  padding: 0;
}
aio-notification .content > * {
  display: flex;
}
aio-notification .content .icon {
  margin-right: 10px;
}
@media (max-width: 464px) {
  aio-notification .content .icon {
    display: none;
  }
}
aio-notification .content .message {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}
aio-notification .content .action-button {
  border-radius: 15px;
  text-transform: uppercase;
  padding: 0 10px;
  font-size: 14px;
  line-height: 20px;
}
@media (max-width: 780px) {
  aio-notification .content .action-button {
    display: none;
  }
}

.aio-notification-show .sidenav-content {
  padding-top: 136px;
}
.aio-notification-show mat-sidenav.mat-sidenav.sidenav {
  top: 112px;
}
@media (max-width: 600px) {
  .aio-notification-show mat-sidenav.mat-sidenav.sidenav {
    top: 112px;
  }
}
.aio-notification-show .toc-container {
  top: 132px;
}
.aio-notification-show .search-results {
  padding-top: 124px;
}
.aio-notification-show.page-home section, .aio-notification-show.page-resources section, .aio-notification-show.page-events section, .aio-notification-show.page-features section, .aio-notification-show.page-presskit section, .aio-notification-show.page-contribute section {
  padding-top: 56px;
}

.aio-notification-animating .sidenav-content {
  transition: padding-top 250ms ease;
}
.aio-notification-animating mat-sidenav.mat-sidenav.sidenav, .aio-notification-animating .toc-container {
  transition: top 250ms ease;
}

label.raised, .api-header label {
  border-radius: 4px;
  padding: 4px 16px;
  display: inline;
  font-size: 16px;
  line-height: 24px;
  margin-right: 8px;
  font-weight: 500;
  text-transform: uppercase;
}
@media screen and (max-width: 600px) {
  label.raised, .api-header label {
    display: block;
    margin: 8px 0;
  }
}
label.raised.page-label, .api-header label.page-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  width: 140px;
}
label.raised.page-label .material-icons, .api-header label.page-label .material-icons {
  margin-right: 8px;
}
label.raised.property-type-label, .api-header label.property-type-label {
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
}

.api-header label {
  padding: 2px 10px;
  font-size: 14px;
  line-height: 20px;
}
@media screen and (max-width: 600px) {
  .api-header label {
    margin: 4px 0;
  }
}

.team-grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 10px;
  row-gap: 20px;
}
.team-grid ngrx-contributor {
  justify-self: center;
  align-self: start;
}
@media only screen and (max-width: 600px) {
  .team-grid {
    grid-template-columns: 1fr;
  }
}

@media print {
  * {
    box-shadow: none !important;
  }
  body, mat-sidenav-container {
    background: none !important;
  }
  h1 {
    height: 40px !important;
    color: #444444 !important;
  }
  h1, h2, h3, h4, h5, h6 {
    page-break-after: avoid;
  }
  ul, ol, img, code-example, table, tr, .alert, .feature {
    page-break-inside: avoid;
  }
  table tbody tr:last-child td {
    border-bottom: 1px solid #DBDBDB !important;
  }
  img {
    max-width: 100% !important;
  }
  p {
    widows: 4;
  }
  p > code, li > code, table code {
    color: #412846 !important;
  }
  .no-print {
    display: none !important;
  }
  mat-sidenav.sidenav.mat-sidenav {
    display: none !important;
  }
  .mat-sidenav-content {
    margin: 0 !important;
  }
  mat-sidenav-container.sidenav-container {
    min-width: 100vw;
  }
  .sidenav-content {
    overflow: visible;
  }
  .filetree {
    max-width: 100%;
  }
  aio-code code {
    border: none !important;
  }
  code-example.code-shell, code-example[language=sh], code-example[language=bash] {
    background: none;
  }
  code-example.code-shell .pnk, code-example.code-shell .blk, code-example.code-shell .pln, code-example.code-shell .otl, code-example.code-shell .kwd, code-example.code-shell .typ, code-example.code-shell .tag, code-example.code-shell .str, code-example.code-shell .atv, code-example.code-shell .atn, code-example.code-shell .com, code-example.code-shell .lit, code-example.code-shell .pun, code-example.code-shell .dec, code-example[language=sh] .pnk, code-example[language=sh] .blk, code-example[language=sh] .pln, code-example[language=sh] .otl, code-example[language=sh] .kwd, code-example[language=sh] .typ, code-example[language=sh] .tag, code-example[language=sh] .str, code-example[language=sh] .atv, code-example[language=sh] .atn, code-example[language=sh] .com, code-example[language=sh] .lit, code-example[language=sh] .pun, code-example[language=sh] .dec, code-example[language=bash] .pnk, code-example[language=bash] .blk, code-example[language=bash] .pln, code-example[language=bash] .otl, code-example[language=bash] .kwd, code-example[language=bash] .typ, code-example[language=bash] .tag, code-example[language=bash] .str, code-example[language=bash] .atv, code-example[language=bash] .atn, code-example[language=bash] .com, code-example[language=bash] .lit, code-example[language=bash] .pun, code-example[language=bash] .dec {
    color: #444444;
  }
  code-example header {
    background: none;
    border: 0.5px solid #DBDBDB;
    color: #444444;
  }
  .content code {
    border: 0.5px solid #DBDBDB;
  }
  .mat-tab-labels div.mat-tab-label:not(.mat-tab-label-active) span {
    font-style: italic;
  }
  .mat-tab-labels div.mat-tab-label.mat-tab-label-active span {
    font-weight: bold;
  }
  .api-header label {
    color: #444444 !important;
    font-weight: bold !important;
    margin: 2px !important;
    padding: 0 !important;
    display: block !important;
  }
  .feature-section img {
    max-width: 70px !important;
  }
}
html {
  --mat-badge-text-font: Roboto, sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, sans-serif;
  letter-spacing: 0.0125em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 16px / 24px Roboto, sans-serif;
  letter-spacing: 0.03125em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 14px / 22px Roboto, sans-serif;
  letter-spacing: 0.0071428571em;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 14px / 20px Roboto, sans-serif;
  letter-spacing: 0.0178571429em;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, sans-serif;
  letter-spacing: 0.0333333333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 96px / 96px Roboto, sans-serif;
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 300 60px / 60px Roboto, sans-serif;
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 48px / 50px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px Roboto, sans-serif;
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: 0.03125em;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: 0.03125em;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-expansion-header-text-font: Roboto, sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-title-text-font: Roboto, sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-node-text-font: Roboto, sans-serif;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto, sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-card-title-text-font: Roboto, sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Roboto, sans-serif;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Roboto, sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto, sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: Roboto, sans-serif;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-label-text-font: Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: Roboto, sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Roboto, sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Roboto, sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}