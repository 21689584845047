@use '../../mixins';

label.raised, .api-header label {
    border-radius: 4px;
    padding: 4px 16px;
    display: inline;
    @include mixins.typescale-default;
    margin-right: 8px;
    font-weight: 500;
    text-transform: uppercase;

    @media screen and (max-width: 600px) {
        display: block;
        margin: 8px 0;
    }

    &.page-label {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        width: 140px;

        .material-icons {
            margin-right: 8px;
        }
    }

    &.property-type-label {
      @include mixins.typescale-small;
        text-transform: none;
    }
}

.api-header label {

    // The API badges should be a little smaller
    padding: 2px 10px;
    @include mixins.typescale-small;

    @media screen and (max-width: 600px) {
        margin: 4px 0;
    }
}
