@use '../../mixins';
@use '../../constants';

/* Button Styles */

.button,
a.button.mat-button {
  display: inline-block;
  line-height: 32px;
  padding: 0px 16px;
  @include mixins.typescale-default;
  font-weight: 400;
  border-radius: 3px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  border: none;

  // SIZES
  &.button-small {
    @include mixins.typescale-small;
    line-height: 24px;
    padding: 0px 8px;
  }

  &.button-large {
    @include mixins.typescale-default;
    line-height: 48px;
    padding: 0px 24px;
  }

  &.button-x-large {
    @include mixins.typescale-large;
    line-height: 56px;
    padding: 0px 24px;
  }


  // COLORS
  &.button-shield,
  &.button-shield.mat-button {
    color: rgba(constants.$white, .87);
    padding-left: 54px;
    background-size: 22px 22px;
  }
}

.cta-bar {
  text-align: center;

  .button {
    margin: 0px 8px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
    transition: all .2s ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
  }
}

a.filter-button {
  width: 140px;
  @include mixins.typescale-default;
  line-height: 48px;
  padding: 0px 16px;
  margin: 8px;
  border-radius: 4px;
}

[mat-button], [mat-raised-button], [mat-button], [mat-raised-button] {
  text-transform: uppercase;
}
